import { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { captureException } from '@sentry/react';
import { type CognitoUser, type CognitoUserSession } from 'amazon-cognito-identity-js';

const usePromptWebSocket = (): void => {
  useEffect(() => {
    // Refresh automatico del token ogni 45 minuti.
    const refreshCognitoSession = async (): Promise<void> => {
      try {
        const user: CognitoUser = await Auth.currentAuthenticatedUser();
        const currentUserSession: CognitoUserSession = await Auth.currentSession();
        user.refreshSession(currentUserSession.getRefreshToken(), (err: Error | null, session: CognitoUserSession) => {
          if (err) {
            console.error('Error refreshing session:', err);
            captureException(err, {
              tags: {
                component: 'webSocketHook'
              }
            });
          }
          // Sessione aggiornata correttamente.
        });
      } catch (err: any) {
        console.error('Error durante il refresh della sessione: ', err);
        captureException(err, {
          tags: {
            component: 'webSocketHook'
          }
        });
      }
    };

    const intervalId = setInterval(refreshCognitoSession, 45 * 60 * 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);
};

export default usePromptWebSocket;
