import UAParser from 'my-ua-parser';

class UaParserSingleton {
  private static instance: UaParserSingleton | null = null;
  private readonly parser: UAParser;
  private readonly deviceType: string | undefined | null;

  private constructor () {
    this.parser = new UAParser();
    this.deviceType = this.parser.getDevice().type;
  }

  public static getInstance (): UaParserSingleton {
    if (!UaParserSingleton.instance) {
      UaParserSingleton.instance = new UaParserSingleton();
    }
    return UaParserSingleton.instance;
  }

  public getDeviceType (): string | undefined | null {
    return this.deviceType;
  }
}

export default UaParserSingleton;
